import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Transforms a given date to UTC with the same year, month, date, hour, and minute.
 * @function
 *
 * @param {Object} date - The date object to be transformed.
 * @returns {Object|null} The transformed date in UTC or null if the input date is invalid.
 */
export const utcTransform = (date) => {
  if (!date) return null;

  return dayjs(date)
    .utcOffset(0)
    .year(date.year())
    .month(date.month())
    .date(date.date())
    .hour(date.hour())
    .minute(date.minute());
};
