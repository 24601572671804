// Main nav menuItems
export const routes = {
  HOME: '/',
  PATIENTS: '/patients',
  USERS: '/users',
  PRESCRIBERS: '/prescribers',
  CENTERS: '/centers',
  PAYING_AGENCIES: '/payingagencies',
  PRESCRIPTIONS: '/prescriptions',
  MAILS: '/mail',
  EVENTS: '/events'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  MAILS: '?s=-created_at'
};
