import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { utcTransform } from '../../utils/utcTransform';
import { useDownloadDocument } from '../../utils/downloadDoc';

/**
 * Hook that returns the columns configuration for the prescriptions table.
 * @hook
 *
 * @param {string} resourceName The name of the resource.
 * @returns {Array<Object>} An array of column configuration objects.
 * Each object contains the following properties:
 * - `title` {string}: The title of the column, translated using the `useTranslation` hook.
 * - `key` {string}: The unique key for the column.
 * - `dataIndex` {string}: The data index for the column.
 * - `sorter` {boolean}: Indicates if the column is sortable.
 * - `render` {function} [optional]: A function to render the column data.
 */
export const usePrescriptionsColumns = (resourceName) => {
  const { t } = useTranslation();
  const { viewDocument } = useDownloadDocument();

  return [
    {
      title: t('patients.columns.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('patients.columns.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('patients.columns.date_of_birth'),
      key: 'date_of_birth',
      dataIndex: 'date_of_birth',
      render: (date) => date && dayjs(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('patients.columns.social_security_number'),
      key: 'social_security_number',
      dataIndex: 'social_security_number',
      sorter: true
    },
    {
      title: t('patients.columns.new_date_prescription'),
      key: 'new_date_prescription',
      dataIndex: 'new_date_prescription',
      render: (date) => date && utcTransform(dayjs(date)).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('prescriptions.columns.duration'),
      key: 'duration',
      dataIndex: 'duration',
      render: (duration) => `${duration} mois`,
      sorter: true
    },
    {
      title: t('patients.columns.soldto'),
      key: 'center.soldto',
      dataIndex: 'center',
      render: (center) => center?.soldto,
      sorter: true
    },
    {
      title: t('patients.columns.center_name'),
      key: 'center.center_name',
      dataIndex: 'center',
      render: (center) => center?.center_name,
      sorter: true
    },
    {
      title: t('patients.columns.finess_number'),
      key: 'center.finess_number',
      dataIndex: 'center',
      render: (center) => center?.finess_number,
      sorter: true
    },
    {
      title: t('prescriptions.columns.prescriber_last_name'),
      key: 'prescriber.last_name',
      dataIndex: 'prescriber',
      render: (prescriber) => prescriber?.last_name,
      sorter: true
    },
    {
      title: t('prescriptions.columns.prescriber_first_name'),
      key: 'prescriber.first_name',
      dataIndex: 'prescriber',
      render: (prescriber) => prescriber?.first_name,
      sorter: true
    },
    {
      title: t('prescriptions.columns.prescriber_am'),
      key: 'health_insurance_id',
      dataIndex: 'prescriber',
      render: (prescriber) => prescriber?.health_insurance_id,
      sorter: true
    },
    {
      title: t('prescriptions.columns.rpps_number'),
      key: 'rpps_number',
      dataIndex: 'prescriber',
      render: (prescriber) => prescriber?.rpps_number,
      sorter: true
    },
    {
      key: 'status',
      render: (record) => {
        const { health_insurance_id, rpps_number } = record.prescriber || {};
        const { finess_number } = record.center || {};

        return (
          <>
            {finess_number && rpps_number && (
              <Tag color="blue">{t('enums.PUBLIC')}</Tag>
            )}
            {rpps_number && health_insurance_id?.length > 0 && (
              <Tag color="pink">{t('enums.PRIVATE')}</Tag>
            )}
          </>
        );
      }
    },
    ...(resourceName === 'prescriptions/download'
      ? [
          {
            key: 'action',
            render: (record) => (
              <EyeOutlined
                style={{ fontSize: 16 }}
                onClick={() => {
                  viewDocument({
                    _id: record?.prescription?.prescription_file
                  });
                }}
              />
            )
          }
        ]
      : [])
  ];
};
