import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Progress, Flex, Typography } from 'antd';

const { Text } = Typography;

/**
 * ProgressModal component displays a modal with a progress bar.
 * It shows the progress of an ongoing task and can display a waiting message.
 *
 * @component
 * @param {boolean} loading - Indicates whether the progress modal is visible or not.
 * @param {string} status - The status of the task. It can be 'active', 'success', or 'exception'.
 * @param {string} message - The message to be displayed when the task is completed successfully.
 * @param {number} dataLength - The length of the data to be processed.
 * @returns {JSX.Element} - The rendered ProgressModal component.
 */
export const ProgressModal = ({ loading, status, message, dataLength }) => {
  const { t } = useTranslation();
  const [percent, setPercent] = useState(0);

  const totalTime = dataLength * 2700;

  useEffect(() => {
    if (!loading) {
      setPercent(0);
    } else {
      const fastPhaseDuration = totalTime * 0.4; // Time for the first 80%
      const slowPhaseDuration = totalTime * 0.6; // Time for the last 20%

      const fastStepTime = fastPhaseDuration / 80; // Interval for each fast %
      const slowStepTime = slowPhaseDuration / 20; // Interval for each slow %

      let interval;
      if (percent < 80) {
        interval = setInterval(() => {
          setPercent((prevPercent) => prevPercent + 1);
        }, fastStepTime);
      } else {
        clearInterval(interval);
        interval = setInterval(() => {
          setPercent((prevPercent) => {
            if (prevPercent >= 99 && status !== 'success') {
              clearInterval(interval);
              return prevPercent; // Stay at 99% until the task is completed
            }
            return status === 'success' ? 100 : prevPercent + 1;
          });
        }, slowStepTime);
      }
      return () => clearInterval(interval);
    }
    return null;
  }, [loading, totalTime, status, percent]);

  return (
    <Modal open={loading} closable={false} footer={false}>
      <Flex
        vertical
        justify="center"
        align="center"
        style={{ margin: '24px 0' }}
      >
        <Text style={{ fontSize: 18, margin: '24px 0' }}>
          {message && t(message)}
        </Text>
        <Progress
          type="circle"
          status={status}
          percent={status === 'success' ? 100 : percent}
        />
      </Flex>
    </Modal>
  );
};

ProgressModal.propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.string.isRequired,
  message: PropTypes.string,
  dataLength: PropTypes.number
};

ProgressModal.defaultProps = {
  loading: false,
  message: null,
  dataLength: 0
};
