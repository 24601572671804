import { Button, DatePicker, Flex, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'dayjs/locale/fr';

/**
 * ExtraFilters component renders a form with filters for prescriptions.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Array} props.prescribers - List of prescribers to populate the prescriber select options.
 * @param {Array} props.patients - List of patients to populate the patient select options.
 * @param {Function} props.setExtraQuery - Function to set the query string based on the selected filters.
 *
 * @returns {JSX.Element} The rendered ExtraFilters component.
 */
export const ExtraFilters = ({ prescribers, patients, setExtraQuery }) => {
  const { t } = useTranslation();

  const prescribersOptions = prescribers.map(
    ({ rpps_number, first_name, last_name, _id }) => ({
      label: `${rpps_number || ''} ${first_name} ${last_name}`,
      value: _id
    })
  );

  const patientsOptions = patients.map(
    ({ social_security_number, first_name, last_name, _id }) => ({
      label: `${social_security_number || ''} ${first_name} ${last_name}`,
      value: _id
    })
  );

  const handleFilteredData = (values) => {
    const { period, prescriber, patient } = values;

    const startDate = period[0].format('YYYY-MM-DD');
    const endDate = period[1].format('YYYY-MM-DD');

    let query = `start_date=${startDate}&end_date=${endDate}`;

    if (prescriber) {
      query = `${query}&prescriber=${prescriber}`;
    }

    if (patient) {
      query = `${query}&patient=${patient}`;
    }

    setExtraQuery(query);
  };

  return (
    <Form onFinish={handleFilteredData}>
      <Flex>
        <Form.Item
          name="period"
          label={t('prescriptions.period')}
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="prescriber" label={t('prescriptions.prescriber')}>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={prescribersOptions}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item name="patient" label={t('prescriptions.patient')}>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={patientsOptions}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {t('buttons.validate')}
        </Button>
      </Flex>
    </Form>
  );
};

ExtraFilters.propTypes = {
  prescribers: PropTypes.arrayOf(PropTypes.shape({})),
  setExtraQuery: PropTypes.func,
  patients: PropTypes.arrayOf(PropTypes.shape({}))
};

ExtraFilters.defaultProps = {
  prescribers: [],
  setExtraQuery: () => {},
  patients: []
};
