import { useState } from 'react';
import {
  Button,
  Tabs,
  Tooltip,
  message as antdMessage,
  notification
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContentCustom,
  ListResource,
  PageHeaderCustom
} from '../../components';
import { usePrescriptionsColumns } from './columns';
import usePatientContext from '../patient/PatientContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { ProgressModal } from '../../components/ProgressModal/ProgressModal';
import { ExtraFilters } from './ExtraFilters';

/**
 * ListPrescriptions component renders a list of prescriptions with functionality to generate or download prescriptions.
 * It uses various hooks and context to manage state and perform actions.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <ListPrescriptions />
 *
 * @description
 * This component provides a tabbed interface to switch between prescriptions to generate and generated prescriptions.
 * It allows users to select patients and either generate or download prescriptions in bulk.
 */
export const ListPrescriptions = () => {
  const { t } = useTranslation();
  const { prescribers, patients } = usePatientContext();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { downloadPDFsAsZip } = useDownloadDocument();

  const [forceRefresh, setForceRefresh] = useState(false);
  const [resourceName, setResourceName] = useState('prescriptions/to-generate');
  const [extraQuery, setExtraQuery] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const [progressModalState, setProgressModalState] = useState({
    loading: false,
    status: 'active'
  });

  const columns = usePrescriptionsColumns(resourceName);

  const handleChangeTab = (key) => {
    if (key === '1') {
      setResourceName('prescriptions/to-generate');
    } else {
      setResourceName('prescriptions/download');
    }
    setSelectedRowKeys([]);
    setForceRefresh((prev) => !prev);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    const selectedData = selectedRows.map((row) => {
      const { _id: patientId, duration, new_date_prescription } = row;
      return { patientId, duration, new_date_prescription };
    });

    setSelectedRowKeys(newSelectedRowKeys);
    setPrescriptionsList(selectedData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const buttonContent =
    resourceName === 'prescriptions/to-generate'
      ? t('prescriptions.columns.generate_prescription')
      : t('prescriptions.columns.download_prescription');

  const handleGeneratePrescriptions = async () => {
    setForceRefresh(false);
    try {
      setProgressModalState({ loading: true, status: 'active' });
      const { data } = await dispatchAPI('POST', {
        url: resourceName,
        body: { prescriptions: prescriptionsList }
      });

      if (data) await downloadPDFsAsZip(data);

      setProgressModalState({ loading: false, status: 'success' });
      setSelectedRowKeys([]);

      setForceRefresh(true);
      return antdMessage.success(t('prescriptions.messages.generated_success'));
    } catch (error) {
      setProgressModalState({ loading: false, status: 'exception' });
      if (error?.response?.data?.description?.details?.length > 0) {
        return error.response.data.description.details.forEach((detail) => {
          notification.error({
            message: t('errors.message.prescription_generation.title'),
            description: t('errors.message.prescription_generation.message', {
              patients: detail.patientName
            }),
            duration: 0
          });
        });
      }
      setForceRefresh(true);
      return message(error);
    }
  };

  const commonListResource = (
    <ListResource
      showAction={false}
      editAction={false}
      deleteAction={false}
      rowKey="unique_key"
      rowSelection={rowSelection}
      withCreateButton={false}
      withImportButton={false}
      withPageHeader={false}
      withSearchBar={false}
      extraQuery={extraQuery}
      extraButtons={
        <Tooltip
          title={
            !selectedRowKeys.length
              ? t('prescriptions.columns.select_patients')
              : ''
          }
        >
          <Button
            disabled={!selectedRowKeys.length}
            type="primary"
            onClick={handleGeneratePrescriptions}
          >
            {buttonContent}
          </Button>
        </Tooltip>
      }
      forceRefresh={forceRefresh}
      resourceName={resourceName}
      resourceModelName="Prescription"
      columns={columns}
      populate="prescriber"
    />
  );

  const items = [
    {
      key: '1',
      label: t('prescriptions.prescriptions_to_generate'),
      children: commonListResource
    },
    {
      key: '2',
      label: t('prescriptions.generated_prescriptions'),
      children: commonListResource
    }
  ];

  return (
    <ContentCustom>
      <PageHeaderCustom title={t('prescriptions.title')} />
      <ExtraFilters
        setExtraQuery={setExtraQuery}
        prescribers={prescribers}
        patients={patients}
      />
      <Tabs defaultActiveKey="1" items={items} onChange={handleChangeTab} />
      <ProgressModal
        loading={progressModalState.loading}
        status={progressModalState.status}
        message="prescriptions.messages.generating"
        dataLength={selectedRowKeys.length}
        setProgressModalState={setProgressModalState}
      />
    </ContentCustom>
  );
};
