import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getPhoneNumber } from '../../utils/formatters';

export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const translationKey = 'centers.form';

  const {
    code,
    created_at,
    last_name,
    first_name,
    usage_name,
    social_security_number,
    date_of_birth,
    rank_birth,
    quality,
    phone_number,
    email,
    address,
    medical_device_serial_number,
    communicator_model,
    medical_device_reference,
    serial_number_communicator,
    latitude_number_fake
  } = data;

  const regex = /(\d)(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})/;
  const formattedNir = social_security_number?.replace(
    regex,
    '$1 $2 $3 $4 $5 $6 $7'
  );

  return [
    {
      label: t(`${translationKey}.code`),
      span: 1,
      content: code || '-'
    },
    {
      label: t(`${translationKey}.created_at`),
      span: 1,
      content: created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-'
    },
    {
      label: t(`${translationKey}.last_name`),
      span: 1,
      content: last_name || '-'
    },
    {
      label: t(`${translationKey}.usage_name`),
      span: 1,
      content: usage_name || '-'
    },
    {
      label: t(`${translationKey}.first_name`),
      span: 1,
      content: first_name || '-'
    },
    {
      label: t(`${translationKey}.social_security_number`),
      span: 1,
      content: (
        <span>
          <span>{formattedNir && formattedNir.slice(0, -2)}</span>
          <span style={{ color: 'red' }}>
            {formattedNir && formattedNir.slice(-2)}
          </span>
        </span>
      )
    },
    {
      label: t(`${translationKey}.date_of_birth`),
      span: 1,
      content: dayjs(date_of_birth).format('DD/MM/YYYY') || '-'
    },
    {
      label: t(`${translationKey}.rank_birth`),
      span: 1,
      content: rank_birth || '-'
    },
    {
      label: t(`${translationKey}.quality`),
      span: 1,
      content: quality ? t(`enums.${quality}`) : '-'
    },
    {
      label: t(`${translationKey}.phone_number`),
      span: 1,
      content:
        phone_number && phone_number.number ? getPhoneNumber(phone_number) : '-'
    },
    {
      label: t(`${translationKey}.email`),
      span: 1,
      content: email || '-'
    },
    {
      label: t(`${translationKey}.address.street`),
      span: 1,
      content:
        address && address?.street && address?.number
          ? `${address.number}, ${address.street}`
          : '-'
    },
    {
      label: t(`${translationKey}.address.postal_code`),
      span: 1,
      content: address?.postal_code || '-'
    },
    {
      label: t(`${translationKey}.address.city`),
      span: 1,
      content: address?.city || '-'
    },
    {
      label: t(`${translationKey}.latitude_number_fake`),
      span: 1,
      content: latitude_number_fake || '-'
    },
    {
      label: t(`${translationKey}.medical_device_reference`),
      span: 1,
      content: medical_device_reference || '-'
    },
    {
      label: t(`${translationKey}.medical_device_serial_number`),
      span: 1,
      content: medical_device_serial_number || '-'
    },
    {
      label: t(`${translationKey}.communicator_model`),
      span: 1,
      content: communicator_model || '-'
    },
    {
      label: t(`${translationKey}.serial_number_communicator`),
      span: 1,
      content: serial_number_communicator || '-'
    }
  ];
};
