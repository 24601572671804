import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { MailRouter } from '../routes/mail/MailRouter';
import { UserRouter } from '../routes/users/UserRouter';
import { PrescriberRouter } from '../routes/prescribers/PrescriberRouteur';
import { CenterRouter } from '../routes/centers/CenterRouteur';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import ConfigurationRouter from './ConfigurationRouter';
import { PatientRouter } from '../routes/patient/PatientRouter';
import { PayingAgencyRouteur } from '../routes/PayingAgency/PayingAgencyRouteur';
import { PrescriptionRouter } from '../routes/prescriptions/PrescriptionRouter';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <AdminLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const AdminRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route path={`${routes.PATIENTS}/*`} element={<PatientRouter />} />
          <Route
            path={`${routes.PAYING_AGENCIES}/*`}
            element={<PayingAgencyRouteur />}
          />
          <Route
            path={`${routes.PRESCRIPTIONS}/*`}
            element={<PrescriptionRouter />}
          />
          <Route path={`${routes.MAILS}/*`} element={<MailRouter />} />
          <Route
            path={`${routes.PRESCRIBERS}/*`}
            element={<PrescriberRouter />}
          />
          <Route path={`${routes.CENTERS}/*`} element={<CenterRouter />} />
          <Route
            path={routes.CONFIGURATIONS}
            element={<ConfigurationRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
