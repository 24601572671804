import dayjs from 'dayjs';
import { Select, Radio, Form, DatePicker, Button, Input, Upload } from 'antd';
import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import usePatientContext from '../PatientContext';

const { Option } = Select;

/**
 * useFields is a custom hook that provides an array of field invoicing for patient data input.
 *
 * @hook
 * @returns {Array<Object>} An array of field configurations.
 */

const useInvoicingFields = (purpose, id, form) => {
  const { t } = useTranslation();
  const {
    enums,
    payingAgencies,
    isSwitchAMC,
    setIsSwitchAMC,
    healthInsurance,
    setHealthInsurance,
    uploadOrDeleteFile,
    isFileLoading
  } = usePatientContext();

  const onsearch = (input, option) => {
    if (Array.isArray(option.children)) {
      const childrenString = option.children.join(' ');
      return childrenString.toLowerCase().includes(input.toLowerCase());
    }
    if (typeof option.children === 'string') {
      const result = option.children.toLowerCase();
      return result.includes(input.toLowerCase());
    }
    return false;
  };

  const endInvoiceDateProcess = (date) => {
    if (date) {
      const endDate = dayjs(date).add(180, 'day');
      form.setFieldsValue({ invoicing_end_date: endDate });
    } else {
      form.setFieldsValue({ invoicing_end_date: null });
    }
  };

  return [
    {
      name: ['registered_invoicing_date'],
      tooltip: true,
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['registered_latitude_date'],
      tooltip: true,
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['last_communication_date'],
      tooltip: true,
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['invoicing_start_date'],
      tooltip: true,
      input: (
        <DatePicker
          format="DD/MM/YYYY"
          onChange={(date) => endInvoiceDateProcess(date)}
        />
      )
    },
    {
      name: ['invoicing_end_date'],
      tooltip: true,
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['invoicing_forecast_date'],
      tooltip: true,
      input: <DatePicker format="DD/MM/YYYY" />
    },
    {
      name: ['lpp_code']
    },
    {
      name: ['lpp_amount'],
      input: <Input type="number" suffix="€" />
    },
    {
      label: 'payingagency_amo.name_agency',
      name: ['payingagency_amo'],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {payingAgencies
            .filter(
              (agency) => agency.type === 'AMO' || agency.type === 'AMO_NO'
            )
            .map((agency) => (
              <Option key={agency._id} value={agency._id}>
                {agency.name_agency} / {agency.transmission_code}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['risk'],
      rules: [{ required: true }],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.risks || []).map((r) => (
            <Option key={r} value={r}>
              {t(`enums.${r}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['exemption'],
      rules: [{ required: true }],
      input: (
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.exemptions || []).map((e) => (
            <Option key={e} value={e}>
              {t(`enums.${e}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['ame'],
      initialValue: false,
      rules: [{ required: true }],
      input: (
        <Radio.Group
          options={[true, false].map((b) => ({
            label: t(`switch.${b}`),
            value: b
          }))}
        />
      )
    },
    {
      name: ['amc'],
      initialValue: isSwitchAMC,
      rules: [{ required: true }],
      input: (
        <Radio.Group
          onChange={(event) => setIsSwitchAMC(event.target.value)}
          options={[true, false].map((b) => ({
            label: t(`switch.${b}`),
            value: b
          }))}
        />
      )
    },
    ...(isSwitchAMC
      ? [
          {
            name: ['payingagency_amc'],
            label: 'payingagency_amc_name',
            input: (
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
              >
                {payingAgencies
                  .filter(
                    (agency) =>
                      agency.type === 'AMC' || agency.type === 'AMC_NO'
                  )
                  .map((agency) => (
                    <Option key={agency._id} value={agency._id}>
                      {agency.name_agency} / {agency.transmission_code}
                    </Option>
                  ))}
              </Select>
            )
          },
          {
            name: ['health_insurance'],
            input: (
              <Upload
                multiple
                maxCount={2}
                beforeUpload={(file) => {
                  if (purpose !== 'edit') {
                    const newFile = [...healthInsurance];
                    newFile.push(file);
                    setHealthInsurance(newFile);
                  } else {
                    uploadOrDeleteFile(file, id, 'upload', 'health_insurance');
                  }
                  return false;
                }}
                onRemove={(doc) => {
                  if (purpose !== 'edit') {
                    const index = healthInsurance.indexOf(doc);
                    const newFile = healthInsurance.slice();
                    newFile.splice(index, 1);
                    setHealthInsurance(newFile);
                  } else {
                    uploadOrDeleteFile(
                      healthInsurance[
                        healthInsurance.findIndex(
                          (file) => file.metadata.originalName === doc.name
                        )
                      ],
                      id,
                      'delete',
                      'health_insurance'
                    );
                  }
                }}
                fileList={
                  isFileLoading
                    ? []
                    : (healthInsurance || []).map((file, index) => ({
                        uid: index,
                        name: file?.metadata?.originalName || file?.name,
                        status: 'done'
                      }))
                }
              >
                <Button icon={<UploadOutlined />}>{t('buttons.upload')}</Button>
              </Upload>
            )
          }
        ]
      : []),
    {
      name: ['reminders_insurance_card'],
      input: (
        <Form.List name="reminders_insurance_card" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(
                (field, index) =>
                  index < 3 && (
                    <div key={field.name}>
                      <Form.Item
                        {...field}
                        key={[field.name, 'type']}
                        name={[field.name, 'type']}
                        label={t('patients.form.reminder_insurance_card.type')}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            onsearch(input, option)
                          }
                        >
                          {(enums.remindersType || []).map((r) => (
                            <Option key={r} value={r}>
                              {t(`patients.enums.${r}`)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <CloseCircleOutlined
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          fontSize: '16px',
                          color: 'red',
                          cursor: 'pointer'
                        }}
                        onClick={() => remove(field.name)}
                      />
                      <Form.Item
                        {...field}
                        key={[field.name, 'date']}
                        name={[field.name, 'date']}
                        label={t('patients.form.reminder_insurance_card.date')}
                      >
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </div>
                  )
              )}
              {fields.length < 3 && (
                <Form.Item>
                  <Button type="primary" onClick={() => add()}>
                    {t('patients.form.reminder_insurance_card.addReminder')}
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      )
    }
  ];
};

export default useInvoicingFields;
