import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

export const useDownloadDocument = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const downloadDocument = async ({
    _id,
    metadata: { originalName },
    contentType
  }) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${_id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (originalName.includes('.pdf')) {
        a.target = '_blank';
      } else a.download = originalName;
      a.click();
      notification.success({ message: t('export.messages.success') });
    } catch (e) {
      message(e);
    }
  };

  const viewDocument = async (doc) => {
    try {
      let blob = {};
      if (!doc.data) {
        const response = await dispatchAPI('GET', {
          url: `/files/${doc._id}`,
          responseType: 'blob'
        });
        blob = new Blob([response.data], {
          type: response.data.type
        });
        const fileURL = URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      }
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  /**
   * Downloads multiple PDF buffers as a ZIP file containing sub-ZIPs for each prescriber.
   * @function
   * @async
   *
   * @param {Array} pdfBuffers - An array of objects containing prescriber information and their respective PDF buffers.
   * @param {string} [zipFilename='prescriptions.zip'] - The name of the resulting ZIP file.
   * @returns {Promise<void>} - A promise that resolves when the ZIP file has been generated and downloaded.
   *
   * @example
   * const pdfBuffers = [
   *   {
   *     prescriber: 'Dr. Smith',
   *     buffers: [
   *       { originalName: 'prescription1.pdf', buffer: { data: ... } },
   *       { originalName: 'prescription2.pdf', buffer: { data: ... } }
   *     ]
   *   },
   *   {
   *     prescriber: 'Dr. Doe',
   *     buffers: [
   *       { originalName: 'prescriptionA.pdf', buffer: { data: ... } }
   *     ]
   *   }
   * ];
   *
   * downloadPDFsAsZip(pdfBuffers, 'my-prescriptions.zip');
   */
  const downloadPDFsAsZip = async (
    pdfBuffers,
    zipFilename = 'prescriptions.zip'
  ) => {
    const zip = new JSZip(); // Create principal ZIP

    // Promisees of sub-ZIPs
    const prescriberPromises = pdfBuffers.map(async (doc) => {
      const prescriberZip = new JSZip(); // Create a sub-ZIP for each prescriber

      // Add prescriber's documents to the sub-zip
      doc.buffers.forEach((buffer, index) => {
        const fileName = buffer.originalName || `document-${index + 1}.pdf`;
        prescriberZip.file(fileName, buffer.buffer.data, { binary: true });
      });

      // Generate the sub-zip to the prescriber
      const content = await prescriberZip.generateAsync({ type: 'blob' });

      // Add sub-zip to the principal ZIP
      zip.file(`${doc.prescriber || 'No-Prescriber'}.zip`, content);
    });

    await Promise.all(prescriberPromises);

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    const url = URL.createObjectURL(zipBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = zipFilename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  return { downloadDocument, viewDocument, downloadPDFsAsZip };
};
