import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { ListPrescriptions } from './ListPrescriptions';
import { PatientContextProvider } from '../patient/PatientContext';

/**
 * PrescriptionRouter component that provides routing for prescription-related pages.
 * It uses the PatientContextProvider to ensure that patient context is available
 * throughout the routes.
 *
 * Routes:
 * - Default route (index): Renders the ListPrescriptions component.
 * - Wildcard route (*): Renders the Exception component for any unmatched routes.
 *
 * @returns {JSX.Element} The PrescriptionRouter component.
 */
export const PrescriptionRouter = () => (
  <PatientContextProvider>
    <Routes>
      <Route index element={<ListPrescriptions />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </PatientContextProvider>
);
